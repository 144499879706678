import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const TitleBar = props => {
  const { primaryText, secondaryText, children, } = props;

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography variant="h1">
          {primaryText}
        </Typography>
        <div style={{marginLeft: 4,}}>
          <Typography color="text.secondary" sx={{fontSize: 14,}}>
            {secondaryText}
          </Typography>
        </div>
      </Grid>
      <Grid item>
        {children}
      </Grid>
    </Grid>
  );
}

export default TitleBar;