import React from 'react';
import Box from '@mui/material/Box';

const styles = ({ backgroundColor, hoverBackgroundColor, iconHoverColor, height, width, }) => ({
  root: {
    margin: "2px",
    borderRadius: "10px",
    height: height ? height : 30,
    width: width ? width : 32,
    backgroundColor: backgroundColor ? backgroundColor : "primary.light",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: hoverBackgroundColor ? hoverBackgroundColor : "primary.main",
      "& .MuiSvgIcon-root": {
        color: iconHoverColor ? iconHoverColor : "white",
      }
    },
  }
});

const JSIconButton = React.forwardRef((props, ref) => {
  const { icon, backgroundColor, hoverBackgroundColor, iconHoverColor, style, height, width, onClick, } = props;
  
  const stylesSX = styles({
    backgroundColor,
    hoverBackgroundColor,
    iconHoverColor,
    height,
    width,
  });

  return (
    <Box
      sx={stylesSX.root}
      onClick={onClick}
      style={style}
      ref={ref}
    >
      {icon}
    </Box>
  );
});

export default JSIconButton;