import React, { useContext, } from 'react';
import Typography from '@mui/material/Typography';
import { FieldContainerContext } from './FieldContainer';

const circular = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (key.startsWith('_')) return // Don't compare React's internal props.
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) return
      seen.add(value)
    }
    return value
  }
}

const ChildrenComp = React.memo(({ children }) => {
  return (
    <>
      {children}
    </>
  )
}, (prevProps, nextProps) => {
  const prev = JSON.stringify(prevProps, circular())
  const next = JSON.stringify(nextProps, circular())
  return prev === next
});

const FieldControl = React.memo((props) => {
  const { label, labelWidth, value, children, viewOnlyComponent, } = props;
  const isView = !useContext(FieldContainerContext);

  return (
    <div
      style={{marginTop: 10, marginBottom: 10,}}
    >
      {isView === true ? (
        <>
          {viewOnlyComponent ? (
            <>
              {viewOnlyComponent}
            </>
          ) : (
            <div style={{
              display: "flex",
              alignItems: "center",
            }}>
              <div
                style={{
                  width: labelWidth||"100%",
                }}
              >
                <Typography variant="h6">{label}</Typography>
              </div>
              <div
                style={{overflow: "hidden", flex: 1}}
              >
                <Typography style={{wordWrap: "break-word"}} color="textSecondary">{value||"--"}</Typography>
              </div>
            </div>
          )}
        </>
      ) : (
        <ChildrenComp>
          {children}
        </ChildrenComp>
      )}
    </div>
  );
});

FieldControl.defaultProps = {
  xtype: 'FieldControl',
}

export default FieldControl;