import React, { useState, useEffect, useCallback, } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import clsx from 'clsx';
import 'ag-grid-enterprise';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useResizeDetector, } from 'react-resize-detector';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmationDialog from './ConfirmationDialog';
import LoadingAnimation from './LoadingAnimation';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

const styles = {
  paper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
}

const AgGridRoot = styled(Box)(({theme}) => ({
  "& .ag-header-cell-label": {
    color: theme.palette.black,
  }
}));

const summaryCount = (rowData) => {
  return rowData.length;
}

const summarySum = (column, rowData) => {
  let total = null;

  for (let i = 0; i < rowData.length; i++) {
    if (!isNaN(rowData[i][column.field])) {
      total += Number(rowData[i][column.field]);
    }
  }

  return total;
}

const summary = (type, column, rowData) => {
  let value = null;
  switch (type) {
    case "count":
      value = summaryCount(rowData);
      break;
    case "sum":
      value = summarySum(column, rowData);
      break;
    default:
      value = null;
  }

  return value;
}

/*
Properties of JSGrid
rowData
columns
style
*/
const JSGrid = React.memo(props => {
  const { rowData, columns, style, columnDefs, defaultColDef, enableCheckboxSelection, enableDelete, selectionControl, masterDetail, detailColumns, detailFrameworkComponents,
    showLoading, forceFit, name,
    onJSGridReady, onDelete, onRowDataChanged, onLoadDetail, getContextMenuItems, columnKeyItems,
    enablePagination, paginationTotalRecords, paginationPageSize, paginationPage, paginationOnChange, toolBarComponents,
    ...otherProps } = props;
  const { width: containerWidth, height: containerHeight, ...otherGridStyles } = style||{};
  
  const theme = useTheme();
  
  const [gridAPI, setGridAPI] = useState(null);
  const [, setGridColumnAPI] = useState(null);
  const [gridColumns, setGridColumns] = useState([]);
  const [enableFilter, setEnableFilter] = useState(false);
  const [enableRowGrouping, setEnableRowGrouping] = useState(null);
  const [footerRows, setFooterRows] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [paginationText, setPaginationText] = useState("");

  const onGridReady = params => {
    setGridAPI(params.api);
    setGridColumnAPI(params.columnApi);

    if (onJSGridReady) {
      onJSGridReady(params.api, params.columnApi);
    }
  }

  const onColumnVisible = () => {
    applyForceFit();
  }

  const onColumnRowGroupChanged = () => {
    applyForceFit();
  }

  const handleFilter = () => {
    setEnableFilter(!enableFilter);
  }

  const handleRowGrouping = () => {
    setEnableRowGrouping(!enableRowGrouping);
  }

  const handleDownloadAsSpreadsheet = () => {
    let exportItems = {
      fileName: name,
      sheetName: "Sheet 1",
    }

    if (columnKeyItems) {
      exportItems.columnKeys = columnKeyItems
    }
    gridAPI.exportDataAsExcel(exportItems);
  }

  const applyForceFit = useCallback(() => {
    if (gridAPI) {
      if (forceFit === false) return;

      setTimeout(() => {
        gridAPI.sizeColumnsToFit();
      });
    }
  }, [gridAPI, forceFit]);

  useEffect(() => {
    if(getContextMenuItems) {
      getContextMenuItems(gridAPI)
    }
  },[getContextMenuItems, gridAPI])

  useEffect(() => {
    if (enableCheckboxSelection === true) {
      let _cols = [{
        field: "jschecker",
        headerName: " ",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 60,
        maxWidth: 60,
        suppressMenu: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        suppressMovable: true,
        suppressFilter: true,
        suppressResize: true,
        suppressCellSelection: true,
        suppressColumnsToolPanel: true,
        filter: false,
      }, ...columns];

      setGridColumns([..._cols]);
    }
    else {
      setGridColumns([...columns]);
    }
  }, [columns, enableCheckboxSelection]);

  useEffect(() => {
    if (!gridAPI) return;

    if (enableFilter === false) {
      gridAPI.setFilterModel(null);
    }
  }, [enableFilter, gridAPI]);

  useEffect(() => {
    if (enableRowGrouping === false) {
      setGridColumns(d => {
        let cols = [...d];

        for (let i = 0; i < cols.length; i++) {
          cols[i].rowGroup = false;
        }

        return cols;
      });
    }
  }, [enableRowGrouping]);

  useEffect(() => {
    applyForceFit();
  }, [applyForceFit]);

  useEffect(() => {
    let result = [];
    let obj = {};

    for (let i = 0; i < gridColumns.length; i++) {
      const col = gridColumns[i];
      if (col.footer) {
        let summaryValue = summary(col.footer, col, rowData);
        if (summaryValue) {
          obj[col.field] = summaryValue;
        }
      }
    }

    if (Object.keys(obj).length > 0) {
      result.push(obj);
    }
    
    setFooterRows([...result]);
  }, [gridColumns, rowData]);

  const onResize = useCallback(() => {
    applyForceFit();
  }, [applyForceFit]);

  useEffect(() => {
    let from = 0;
    let to = 0;
    const paginationTotalPages = Math.ceil(paginationTotalRecords / paginationPageSize);

    if (paginationPage === 1) {
      from = 1;
    }
    else {
      from = (paginationPage - 1) * paginationPageSize + 1;
    }

    if (paginationPage < paginationTotalPages) {
      to = paginationPageSize * paginationPage;
    }
    else {
      to = paginationTotalRecords;
    }

    setPaginationText(`${from} to ${to} of ${paginationTotalRecords}`);
  }, [paginationPage, paginationTotalRecords, paginationPageSize]);

  const detailCellRendererParams = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
        columnDefs: detailColumns,
        frameworkComponents: detailFrameworkComponents,
    },
    
    getDetailRowData: async (params) => {
      let data = []

      if (onLoadDetail) {
        data = await onLoadDetail(params.data.id);
      }

      params.successCallback(data || []);
    }
  };

  const { ref: xRef } = useResizeDetector({ onResize });

  return (
    <Paper
      sx={styles.paper}
      style={{width: containerWidth, height: containerHeight||"100%",}}
      ref={xRef}
    >
      <LoadingAnimation open={showLoading||false} style={{position: "absolute"}} />
      <ConfirmationDialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        title="Confirm Delete"
        message={`Are you sure you want to delete the ${selectedRowCount} row(s) selected?`}
        onDeny={() => setShowDeleteConfirmation(false)}
        onAffirm={onDelete}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {selectedRowCount > 0 && (
          <div style={{paddingLeft: 5, display: "flex", alignItems: "center",}}>
            <Typography color="textSecondary" component="span">{selectedRowCount} row(s) selected</Typography>
            {enableDelete && (
              <Tooltip title={`Delete ${selectedRowCount} selected row(s)`}>
                <IconButton
                  size="small"
                  style={{color: theme.palette.red}}
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            <div>
              {selectionControl}
            </div>
          </div>
        )}
        {toolBarComponents && (
          <div
            style={{marginLeft: 5,}}
          >
            {toolBarComponents}
          </div>
        )}
        <div style={{flexGrow: 1,}}></div>
        <div
          style={{marginRight: 5, marginLeft: 5,}}
        >
          <Tooltip
            title="Filter"
          >
            <IconButton
              size="small"
              color="secondary"
              onClick={handleFilter}
            >
              <FilterListOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Group columns"
          >
            <IconButton
              size="small"
              color="secondary"
              onClick={handleRowGrouping}
            >
              <ListOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Download as Spreadsheet"
          >
            <IconButton
              size="small"
              color="secondary"
              onClick={handleDownloadAsSpreadsheet}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <AgGridRoot
        className={clsx("ag-theme-material", "core-theme")}
        style={{
          width: "100%",
          height: "100%",
          ...otherGridStyles,
        }}
      >
        <AgGridReact
          rowData={rowData}
          defaultColDef={{
            resizable: true,
            sortable: true,
            filter: "agMultiColumnFilter",
            enablePivot: true,
            ...defaultColDef,
          }}
          getContextMenuItems={getContextMenuItems}
          masterDetail={masterDetail || false}
          detailCellRendererParams={detailCellRendererParams}
          columnDefs={columnDefs}
          maintainColumnOrder={true}
          rowGroupPanelShow={enableRowGrouping === true ? "always" : "never"}
          suppressDragLeaveHidesColumns={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
            ],
            defaultToolPanel: "",
          }}
          pinnedBottomRowData={footerRows}
          rowSelection={enableCheckboxSelection === true ? "multiple" : "single"}
          suppressRowClickSelection={enableCheckboxSelection}
          suppressCellSelection={true}
          onGridReady={onGridReady}
          onColumnVisible={onColumnVisible}
          onColumnRowGroupChanged={onColumnRowGroupChanged}
          onSelectionChanged={(e) => {
            if (enableCheckboxSelection !== true) return;
            
            const selectedRows = gridAPI.getSelectedNodes();
            setSelectedRowCount(selectedRows.length);
          }}
          onRowDataChanged={() => {
            applyForceFit();
            if (onRowDataChanged) {
              onRowDataChanged();
            }
          }}
          {...otherProps}
        >
          {gridColumns.map((column, index) => (
            <AgGridColumn
              key={index}
              {...column}
              floatingFilter={enableFilter}
              enableRowGroup={enableRowGrouping === null ? false : enableRowGrouping}
            />
          ))}
        </AgGridReact>
      </AgGridRoot>
      {enablePagination === true && Math.ceil(paginationTotalRecords / paginationPageSize) > 1 && (
        <div style={{marginTop: 10, marginBottom: 10,}}>
          <Stack spacing={1} justifyContent="flex-end" direction="row" alignItems="center">
            <Typography color="text.secondary">
              {paginationText}
            </Typography>
            <Pagination
              color="secondary"
              count={Math.ceil(paginationTotalRecords / paginationPageSize)}
              page={paginationPage}
              onChange={paginationOnChange}
            />
          </Stack>
        </div>
      )}
    </Paper>
  );
});

export default JSGrid;