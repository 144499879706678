import React, { useEffect, useRef, } from 'react';
import { useParams, useLocation, } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoadingAnimation from './LoadingAnimation';
import ConfirmationDialog from './ConfirmationDialog';

const styles= {
  root: {
    height: "100%",
    backgroundColor: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    overflow: "hidden",
    flexDirection: "column",
    position: "relative",
  },
  snackBar: {
    position: "absolute",
  }
}

const Form = (props) => {
  const { open, onClose, title, secondaryTitle, showSuccess, successMessage, onSuccessMessageClose, errorMessage, onErrorMessageClose, actionBar, pageNotFound,
    loading, showConfirmation, onCloseConfirmation, onDenyConfirmation, onAffirmConfirmation, pageNotFoundMessage, children, } = props;

  const showError = Boolean(errorMessage);
  const containerRef = useRef(null);
  const routerLocation = useLocation();
  const routerParams = useParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    }
  }, [routerLocation.pathname, routerParams.id]);

  return (
    <>
      <LoadingAnimation
        style={{position: "absolute",}}
        open={loading||false}
      />
      <ConfirmationDialog
        open={showConfirmation||false}
        onClose={onCloseConfirmation}
        message="Are you sure you want to save your changes?"
        onDeny={onDenyConfirmation}
        onAffirm={onAffirmConfirmation}
      />
      <Snackbar
        open={showSuccess}
        autoHideDuration={5000}
        onClose={onSuccessMessageClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={styles.snackBar}
      >
        <MuiAlert
          severity="success"
          onClose={onSuccessMessageClose}
        >
          {successMessage||"Records saved successfully!"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={5000}
        onClose={onErrorMessageClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={styles.snackBar}
        transitionDuration={{ exit: 0 }}
      >
        <MuiAlert
          severity="error"
          onClose={onErrorMessageClose}
        >
          {errorMessage||"Error processing transaction. Please review your inputs."}
        </MuiAlert>
      </Snackbar>
      <Paper sx={styles.root} style={{display: open === true ? "flex" : "none",}}>
        <div
          style={{
            minHeight: 60,
            padding: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              padding: "5px",
              paddingBottom: "10px",
              display: "flex",
              borderBottom: "1px solid #bbb",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              style={{height: 40,}}
              onClick={onClose}
            >
              <ArrowBack />
            </Button>
            <div style={{flexGrow: 1, marginLeft: 10,}}>
              <Typography variant="h1">{title}</Typography>
              <div style={{marginLeft: 3,}}>
                <Typography color="textSecondary">{secondaryTitle}</Typography>
              </div>
            </div>
          </div>
        </div>
        {pageNotFound !== true ? (
          <>
            <div
              ref={containerRef}
              style={{
                overflow: "auto",
                padding: "5px",
                flex: 1,
              }}
            >
              {children}
            </div>
            <>
              {actionBar && (
                <div
                  style={{
                    height: 60,
                    borderTop: "1px solid #ddd",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  {actionBar}
                </div>
              )}
            </>
          </>
        ) : (
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 1,}}>
            <Typography variant="h4">{pageNotFoundMessage||"Page not found!"}</Typography>
          </div>
        )}
      </Paper>
    </>
  );
}

export default Form;