import React from 'react';
import JSIconButton from '../JSIconButton';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Tooltip from '@mui/material/Tooltip';

const FilterIconButton = (props) => {
  const { onClick, filtered, } = props;

  return (
    <div
      style={{position: "relative",}}
    >
      <div
        style={{position: "absolute", height: 10, width: 10, backgroundColor: "green", borderRadius: 10, right: 3, top: 5,}}
        hidden={!filtered}
      />
      <JSIconButton
        icon={<Tooltip title="Filter"><TuneOutlinedIcon style={{color: "white"}} /></Tooltip>}
        backgroundColor="secondary.main"
        hoverBackgroundColor="secondary.dark"
        onClick={onClick}
        height={37}
        width={38}
      />
    </div>
  );
}

export default FilterIconButton;