import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingAnimation from './LoadingAnimation';

const View = (props) => {
  const { loading, openForm, successMessage, onSuccessMessageClose, errorMessage, onErrorMessageClose, children, } = props;

  const showSuccess = Boolean(successMessage);
  const showError = Boolean(errorMessage);

  return (
    <>
      <LoadingAnimation open={loading||false} style={{ position: "absolute" }} />
      <Snackbar
        open={showSuccess}
        autoHideDuration={5000}
        onClose={onSuccessMessageClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{position: "absolute",}}
      >
        <MuiAlert
          severity="success"
          onClose={onSuccessMessageClose}
        >
          {successMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={5000}
        onClose={onErrorMessageClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{position: "absolute",}}
      >
        <MuiAlert
          severity="error"
          onClose={onErrorMessageClose}
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
      <div
        style={{
          display: openForm === true ? "none" : "flex",
          height: "100%",
          overflow: "hidden",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
    </>
  );
}

export default View;