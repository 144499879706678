import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ViewParameterForm = (props) => {
  const { open, onClose, title, children, onFilter, onReset, } = props;

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: "absolute",
          minHeight: "100%",
          maxHeight: "100%",
          right: 0,
          margin: 0,
          padding: 0,
        }
      }}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle
        sx={{ fontSize: "20px", }}
      >
        {title||"Filter"}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            "& > *": {
              marginTop: "15px !important",
            }
          }}
        >
          {children}
        </Box>
      </DialogContent>
      <DialogActions
        style={{borderTop: "1px solid #ddd", display: "block",}}
      >
        <div style={{display: "flex",}}>
          <div style={{flex: 1,}}>
            {onReset && (
              <Button
                color="secondary"
                onClick={onReset}
              >
                RESET
              </Button>
            )}
          </div>
          <Button
            variant="contained"
            onClick={onFilter}
            style={{marginRight: 5,}}
          >
            FILTER
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
          >
            CLOSE
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ViewParameterForm;
