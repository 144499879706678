import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationDialog = (props) => {
  const { open, onClose, onAffirm, onDeny, title, titleColor, message, denyCaption, affirmCaption, } = props;

  const handleAffirm = () => {
    if (onClose) {
      onClose();
    }
    
    if (onAffirm) {
      onAffirm();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle style={{ color: titleColor||"" }}>
        {title||"Confirmation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{whiteSpace: "pre-line"}}>
          {message||"Please provide message props"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onDeny} color="secondary">
          {denyCaption||"NO"}
        </Button>
        <Button onClick={handleAffirm} color="secondary">
          {affirmCaption||"YES"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;