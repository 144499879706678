import React from 'react';

// const UpdatedChildren = (props) => {
//   let _children = null;
//   const { children, editable, } = props;

//   const iterateChildren = (fChildren) => {
//     return Children.map(fChildren, child => {
//       if (child && child.props.xtype === "FieldControl") {
//         return React.cloneElement(child, { isView: !editable, })
//       }
//       else if (child && child.children) {
//         iterateChildren(child.children)
//       }
//       else {
//         return child;
//       }
//     });
//   }

//   _children = iterateChildren(children);

//   return _children;
// };

export const FieldContainerContext = React.createContext();

const FieldContainer = (props) => {
  const { children, editable, } = props;

  return (
    <FieldContainerContext.Provider value={editable}>
      {children}
    </FieldContainerContext.Provider>
  );
};

export default FieldContainer;