import moment from "moment";
import JSGrid from "../components/JSGrid";
import TitleBar from "../components/TitleBar";
import View from "../components/View";
import jsAPI from "../helpers/jsAPI";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Button, FormGroup, IconButton, Tooltip, Typography, FormControlLabel, Switch} from "@mui/material";
import UserContext from "../context/UserContext";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import OngoingEvaluationForm from "../forms/OngoingEvaluationForm";
import qs from 'qs';
import FilterIconButton from '../components/IconButtons/FilterIconButton';
import ViewParameterForm from "../components/ViewParameterForm";


const ProgressBar = ({ value }) => {
  const getProgressColor = (value) => {
    if (value >= 90) return '#32CD32'; // Lime green for 90% and above
    if (value >= 70) return '#228B22'; // Medium dark green for 70% and above
    if (value >= 50) return '#006400'; // Dark green for 50% and above
    
    return '#34bb8b'; // Lighter green for less than 50%
  };

  return (
    <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: 3 }}>
      <Box sx={{ width: '100%', background: '#b1e5d2', borderRadius: '4px', height: '8px', overflow: 'hidden', position: 'relative' }}>
        <Box style={{ width: `${value}%`, background: getProgressColor(value), height: '100%' }} />
      </Box>
      <Typography>{Math.round(value)}%</Typography> 
    </Box>
  )
}

const GridActions = ({ employee_id }) => {
  const [user] = useContext(UserContext);
  const history = useHistory();
  const entryPath = `/${user.client_code}/app/performance_management/approved_evaluations`;
  
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "0.3125rem" }}>
      <Tooltip title="Open">
        <IconButton
          color="primary"
          size="small"
          onClick={() => history.push(`${entryPath}/${employee_id}`)}
        >
          <LaunchOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

const OngoingEvaluations = () => {
  const routerLocation = useLocation();
  const routerParams = useParams();
  const history = useHistory();

  const [user] = useContext(UserContext);

  const entryPath = `/${user.client_code}/app/performance_management/approved_evaluations`;
  const searchPath = `/${user.client_code}/app/performance_management/approved_evaluations/filter`;

  const parameterDefaultValues = {
    all: true,
    my_evaluations: true,
    my_evaluatees: true,
    finished_evaluations: true
  };

  const [masterData, setMasterData] = useState([]);
  const [detailData, setDetailsData] = useState([]);
  const [pageSize, setPageSize] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMasterData, setIsLoadMasterData] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageNo, setPageNo] = useState(1)
  const [openParameter, setOpenParameter] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [parameters, setParameters] = useState({ ...parameterDefaultValues });
  const [selectedTab, setSelectedTab] = useState('evaluatees');
  const [pmsCreator, setPMSCreator] = useState(null);

  const columns = [
    { field: "id", headerName: "Id", hide: true },
    { field: "reference_number", headerName: "Reference Number", cellRenderer: "agGroupCellRenderer" },
    { field: "title", headerName: "Title" },
    { field: "year", headerName: "Year", valueFormatter: (params) => params.value ? params.value : "- -"},
    { field: "coverage_start", headerName: "Coverage Start Date", valueFormatter: (params) => params.value ? moment.utc(params.value).local().format("MM/DD/YYYY")  : "- -" },
    { field: "coverage_end", headerName: "Coverage End Date", valueFormatter: (params) => params.value ? moment.utc(params.value).local().format("MM/DD/YYYY")  : "- -" },
    { field: "evaluation_start", headerName: "Evaluation Start", valueFormatter: (params) => params.value ? moment.utc(params.value).local().format("MM/DD/YYYY")  : "- -" },
    { field: "evaluation_end", headerName: "Evaluation End", valueFormatter: (params) => params.value ? moment.utc(params.value).local().format("MM/DD/YYYY")  : "- -" },
    { field: "number_of_employees", headerName: "Number of Employees" },
    { field: "number_of_goals", headerName: "Number of Goals" },
  ];

  const detailCellRendererParams = {
    detailGridOptions: {
      columnDefs: [
        { field: "", headerName: "", width: 60, suppressColumnsToolPanel: true, resizable: false, suppressSizeToFit: true, suppressMenu: true, cellRendererFramework: (params) => <GridActions employee_id={params.data.employee_id} /> },
        { field: "employee_id", headerName: "Id", hide: true, width: 80, suppressColumnsToolPanel: true, resizable: false },
        { field: "code", headerName: "Code", width: 100, suppressColumnsToolPanel: true, resizable: false },
        { field: "employee_name", headerName: "Name" },
        { field: "position_name", headerName: "Position", flex: 1 },
        { field: "supervisory_organization", headerName: "Supervisory Organization", flex: 1 },
        { field: "employee_progress",  hide: selectedTab !== 'finished evaluations' ? false : pmsCreator !== user.user_id ? false : true, headerName: "Employee Progress", flex: 1 , cellRendererFramework: (params) => <ProgressBar value={params.value} /> },
        { field: "evaluator_progress", hide: selectedTab !== 'finished evaluations' ? false : pmsCreator === user.user_id ? false : true, headerName: "Evaluator Progress", flex: 1 , cellRendererFramework: (params) => <ProgressBar value={params.value} /> },
        { field: pmsCreator === user.user_id ? "evaluator_score" : "employee_score", headerName: "Score", hide: selectedTab !== "finished evaluations"},
        { field: pmsCreator === user.user_id ? "evaluator_remark" : "employee_remark", headerName: "Remark", hide: selectedTab !== "finished evaluations"},
      ]
    },
    getDetailRowData: async (params) => {
      const reponse = await loadDetailData(params.data.id);
      params.successCallback(reponse);
    },
  }

  const loadMasterData = useCallback(async () => {
    setIsLoading(true)

    try {
      const responseJSON = await jsAPI.request("/api/evaluation_requests/get_ongoing_req_eval?" + new URLSearchParams({
        page_no: pageNo,
        ...parameters
      }));

      if (responseJSON.success) {
        const { result, total_rows, page_size } = responseJSON;        
        setMasterData([...result]);
        setPageSize(page_size);
        setTotalRows(total_rows);
      }

    } 
    catch (error) {
      setErrorMessage("Error loading Approved Evaluations.");
    }
    finally {
      setIsLoading(false)
    }
  }, [pageNo])

  const loadDetailData = async (id) => {    
    const responseJSON = await jsAPI.request("/api/evaluation_requests/get_employee_details?" + new URLSearchParams({
      id: id,
      ...parameters
    }));    
    setDetailsData(responseJSON)
    return responseJSON;
  }

  const handleOnChangePagination = (event, value) => {
    const queryString = qs.parse(routerLocation.search, { ignoreQueryPrefix: true });
    queryString.page_no = value;

    history.push(`${entryPath}?${qs.stringify(queryString)}`);
  }

  const handleCloseForm = () => {
    setOpenForm(false);
    history.push(entryPath); 
  }

  const loadFilter = async (filter) => {
    setIsLoading(true);
    try {      
      const responseJSON = await jsAPI.request("/api/evaluation_requests?" + new URLSearchParams({
        page_no: pageNo,
        ...(filter ?? parameters)
      }));
      if (responseJSON.success) {
        const {data, total_rows, page_size} = responseJSON
        setMasterData([...data]);
        setPageSize(page_size);
        setTotalRows(total_rows);
        setPMSCreator(data[0]?.created_by);
      }
    } 
    catch (error) {
      setErrorMessage("Error loading Approved Evaluations.");
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleFilter = () => {
    history.push(`${searchPath}?${qs.stringify(parameters)}`);
    loadFilter();
    setOpenParameter(false);
    setFiltered(true);
  }

  const handleResetParameters = () => {
    history.push(entryPath);
    setParameters({ ...parameterDefaultValues });
    loadFilter();
    setOpenParameter(false);
    setFiltered(false);
  }

  const handleParameterChange = (field, value) => {    
    const obj = {};
    obj[field] = value;
    setParameters(val => ({ ...val, ...obj }));
  }

  const handleEvaluateesClick = () => {
    setSelectedTab('evaluatees')

    handleParameterChange("all", false);
    handleParameterChange("my_evaluations", false);
    handleParameterChange("my_evaluatees", true);
    handleParameterChange("finished_evaluations", false);


    setMasterData([])
    setDetailsData([])

    loadFilter({
      all:false,my_evaluatees:true,my_evaluations:false
    })
  }

  const handleEvaluationsClick = () => {
    setSelectedTab('evaluations')

    handleParameterChange("all", false);
    handleParameterChange("my_evaluations", true);
    handleParameterChange("my_evaluatees", false);
    handleParameterChange("finished_evaluations", false);

    setMasterData([])
    setDetailsData([])

    loadFilter({
      all:false,my_evaluatees:false,my_evaluations:true
    })
  }

  const handleFinishedEvaluationsClick = () => {    
    setSelectedTab('finished evaluations')

    handleParameterChange("all", false);
    handleParameterChange("my_evaluations", false);
    handleParameterChange("my_evaluatees", false);
    handleParameterChange("finished_evaluations", true);


    setMasterData([])
    setDetailsData([])

    loadFilter({
      all:false,my_evaluatees:false,my_evaluations:false,finished_evaluations:true
    })
  }
  

  useEffect(() => {
    if (routerLocation.pathname.toLowerCase() === entryPath) {
      setOpenForm(false);

      const queryString = qs.parse(routerLocation.search, { ignoreQueryPrefix: true });
      if (!queryString.page_no) {
        setPageNo(1)
        history.push(`${entryPath}?${qs.stringify({page_no: 1,})}`);
      }
      else {
        setPageNo(Number(queryString.page_no || 1));
        handleParameterChange("all", false);
        handleParameterChange("my_evaluations", false);
        handleParameterChange("my_evaluatees", true);
        handleParameterChange("finished_evaluations", true);
        loadFilter({
          all:false,my_evaluatees:false,my_evaluations:false,finished_evaluations:true
        })
        // setIsLoadMasterData(true);
      }
    }
    else if (routerLocation.pathname.toLowerCase() === searchPath) {
      setOpenForm(false);
    }
    else {
      setOpenForm(true);
    }
  }, [routerLocation.pathname, routerLocation.search, history, entryPath])

  useEffect(() => {
    if (isLoadMasterData) {
      loadMasterData();
      setIsLoadMasterData(false);
    }
  }, [isLoadMasterData, loadMasterData]);

  return (
    <>
      <View
        openForm={openForm}
        errorMessage={errorMessage}
        onErrorMessageClose={() => setErrorMessage("")}
        open={openParameter}
        onFilter={handleFilter}
        onReset={handleResetParameters}
      >
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <TitleBar
            primaryText="Approved Evaluations"
            secondaryText="List of Approved Evaluations"
          >
          </TitleBar>
        </Box>
        <Box
          sx={{
            display:"flex",
            gap: "4px",
            marginLeft: "4px"
          }}
        >
          <Box
            sx={{
              backgroundColor:selectedTab == 'evaluatees' ? "white":"primary.main",
              padding: "8px",
              borderRadius: "8px 8px 0px 0px",
            }}
            onClick={handleEvaluateesClick}
          >
            <Typography>
              My Evaluatees
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor:selectedTab == 'evaluations' ? "white":"primary.main",
              padding: "8px",
              borderRadius: "8px 8px 0px 0px",
            }}
            onClick={handleEvaluationsClick}
          >
            <Typography>
              My Evaluations
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor:selectedTab == 'finished evaluations' ? "white":"primary.main",
              padding: "8px",
              borderRadius: "8px 8px 0px 0px",
            }}
            onClick={handleFinishedEvaluationsClick}
          >
            <Typography>
              Finished Evaluations
            </Typography>
          </Box>
        </Box>

        <JSGrid
          rowData={masterData}
          columns={columns}
          showLoading={isLoading}
          masterDetail
          detailRowAutoHeight
          detailCellRendererParams={detailCellRendererParams}
          detailRowHeight={30}
          enablePagination
          paginationTotalRecords={totalRows}
          paginationPageSize={pageSize}
          paginationPage={pageNo}
          paginationOnChange={handleOnChangePagination}
        />
      </View>

      {openForm && 
        <OngoingEvaluationForm 
          openForm={openForm}
          onClose={handleCloseForm}
          detailData={detailData}          
          selectedTab={selectedTab}
        />
      }
    </>
  )
}

export default OngoingEvaluations
