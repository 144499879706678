import React from 'react';
import { useTheme, } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const LoadingAnimation = (props) => {
  const { open, style, message, fullScreen, } = props;
  const theme = useTheme();
  
  let nStyle = { ...style };
  if (fullScreen === false) {
    nStyle.position = "absolute";
  }

  return (
    <Backdrop
      style={{
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        ...nStyle,
      }}
      open={open}
    >
      <div>
        <CircularProgress color="secondary" />
        <Typography>{message||"Loading..."}</Typography>
      </div>
    </Backdrop>
  )
}

export default LoadingAnimation;